import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { layoutEmpresa } from "../../config/layoutEmpresa.js";
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import Vue from "vue";
import { format, parseISO } from "date-fns";
import { fetchEmpresa } from "@/api/empresas/empresas.js";
import { getCaixaAfSaldosPeriodo } from "@/api/relatorios/relatorio_caixas_pdv.js";
import { i18n } from "@/plugins/i18n";
import store from "@/store/modules/usuario.store.js";

export async function generatePdf() {
  let result = {
    error: false,
    message: null,
    pdfData: null,
  };

  const bodyPDF = await layoutEmpresa();
  const filtro_get_extrato = bodyPDF.queryString;
  const periodo = bodyPDF.periodo;
  const cabecalho = bodyPDF.cabecalho;
  const caixa_cobros = await getCaixaAfSaldosPeriodo(filtro_get_extrato);
  const caixa_af_cobros = caixa_cobros.result;
  const caixa_nome = caixa_cobros.caixa_nome;

  const total_abertura = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "ABERTURA";
  });

  const total_fechamento = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "FECHAMENTO";
  });

  const total_vendas = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE VENDAS";
  });
  const total_cobros_efetivo = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE COBROS EFETIVO";
  });
  const total_gastos = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE GASTOS";
  });
  const total_cobros_credito = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE COBROS CREDITO";
  });
  const total_cobros_debito = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE COBROS DEBITO";
  });
  const total_cobros_cheque = caixa_af_cobros.find((cobro) => {
    return cobro.tipo === "TOTAL DE COBROS CHEQUE";
  });

  return new Promise((resolve, reject) => {
    let docDefinition = {};
    let fontItem = 12;
    let geral = [];

    let content = [cabecalho];

    let cabecalho_saldo_caixa = {
      margin: [0, 0, 0, 5],
      table: {
        widths: ["*"],
        body: [
          [
            {
              text: caixa_nome + " - SALDO -" + periodo,
              fillColor: "#EBEEF9",
              border: [false, false, false, false],
              bold: true,
              alignment: "center",
              // margin: [0, 4, 0, 2],
              fontSize: 10,
            },
          ],
        ],
      },
    };
    content.push(cabecalho_saldo_caixa);

    let corpo_totais = {
      margin: [0, 10, 0, 0],
      table: {
        widths: ["*", "*", "*", "*"],
        body: [
          [
            {
              fontSize: 10,
              margin: [10, 10, 10, 10],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text:
                i18n.tc("global.total").toUpperCase() +
                " " +
                i18n.tc("global.transacoes").toUpperCase(),
              colSpan: 4,
            },
            {},
            {},
            {},
          ],
          [
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: i18n.tc("global.tipo").toUpperCase(),
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: "U$",
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: "G$",
            },
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: "R$",
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };
    let total_dolar = 0;
    let total_real = 0;
    let total_guarani = 0;

    if (total_cobros_efetivo) {
      total_dolar += Number(total_cobros_efetivo.dolar);
      total_real += Number(total_cobros_efetivo.real);
      total_guarani += Number(total_cobros_efetivo.guarani);
      let body_total_cobros_efetivo = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_cobros_efetivo.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_efetivo.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("guarani")(total_cobros_efetivo.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_efetivo.real),
        },
      ];
      corpo_totais.table.body.push(body_total_cobros_efetivo);
    }
    if (total_cobros_credito) {
      total_dolar += Number(total_cobros_credito.dolar);
      total_real += Number(total_cobros_credito.real);
      total_guarani += Number(total_cobros_credito.guarani);
      let body_total_cobros_credito = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_cobros_credito.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_credito.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("guarani")(total_cobros_credito.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_credito.real),
        },
      ];
      corpo_totais.table.body.push(body_total_cobros_credito);
    }
    if (total_cobros_debito) {
      total_dolar += Number(total_cobros_debito.dolar);
      total_real += Number(total_cobros_debito.real);
      total_guarani += Number(total_cobros_debito.guarani);
      let body_total_cobros_debito = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_cobros_debito.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_debito.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("guarani")(total_cobros_debito.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_debito.real),
        },
      ];
      corpo_totais.table.body.push(body_total_cobros_debito);
    }
    if (total_cobros_cheque) {
      total_dolar += Number(total_cobros_cheque.dolar);
      total_real += Number(total_cobros_cheque.real);
      total_guarani += Number(total_cobros_cheque.guarani);
      let body_total_cobros_cheque = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_cobros_cheque.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_cheque.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("guarani")(total_cobros_cheque.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_cobros_cheque.real),
        },
      ];
      corpo_totais.table.body.push(body_total_cobros_cheque);
    }
    if (total_vendas) {
      let body_total_vendas = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_vendas.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",

          text: Vue.filter("currency")(total_vendas.dolar) + " *",
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",

          text: Vue.filter("guarani")(total_vendas.guarani) + " *",
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",

          text: Vue.filter("currency")(total_vendas.real) + " *",
        },
      ];
      corpo_totais.table.body.push(body_total_vendas);
    }
    if (total_gastos) {
      total_dolar -= Number(total_gastos.dolar);
      total_real -= Number(total_gastos.real);
      total_guarani -= Number(total_gastos.guarani);
      let body_total_gastos = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_gastos.tipo,
          fillColor: "#f59f9f",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          fillColor: "#f59f9f",
          text: Vue.filter("currency")(total_gastos.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          fillColor: "#f59f9f",
          text: Vue.filter("guarani")(total_gastos.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          fillColor: "#f59f9f",
          text: Vue.filter("currency")(total_gastos.real),
        },
      ];
      corpo_totais.table.body.push(body_total_gastos);
    }
    let body_totais = [
      {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "center",
        text: "TOTAIS",
        fillColor: "#EEEEEE",
        bold: true,
      },
      {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
        fillColor: "#EEEEEE",
        text: Vue.filter("currency")(total_dolar),
      },
      {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
        fillColor: "#EEEEEE",
        text: Vue.filter("guarani")(total_guarani),
      },
      {
        fontSize: 10,
        margin: [5, 5, 5, 5],
        alignment: "right",
        fillColor: "#EEEEEE",
        text: Vue.filter("currency")(total_real),
      },
    ];
    corpo_totais.table.body.push(body_totais);

    let corpo_abertura_fechamento = null;

    if(total_abertura || total_fechamento){
          //preciso criar uma nova tabela, com os valores de abertura e fechamento apenas
    corpo_abertura_fechamento = {
      margin: [0, 10, 0, 0],
      table: {
        widths: ["*", "*", "*", "*"],
        body: [
          [
            {
              fontSize: 10,
              margin: [10, 10, 10, 10],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text:
                i18n.tc("global.caixa").toUpperCase() +
                " " +
                i18n.tc("global.abertura").toUpperCase() +
                " - " +
                i18n.tc("global.fechamento").toUpperCase(),
              colSpan: 4,
            },
            {},
            {},
            {},
          ],

          [
            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: i18n.tc("global.tipo").toUpperCase(),
            },

            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: "U$",
            },

            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: "G$",
            },

            {
              fontSize: 10,
              margin: [5, 5, 5, 5],
              alignment: "center",
              fillColor: "#EBEEF9",
              bold: true,
              text: "R$",
            },
          ],
        ],
      },
      layout: {
        hLineWidth: function (i, node) {
          return i === 0 || i === node.table.body.length ? 0.5 : 0.5;
        },
        vLineWidth: function (i, node) {
          return i === 0 || i === node.table.widths.length ? 0.5 : 0.5;
        },
      },
    };


    if (total_abertura) {
      let body_total_abertura = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_abertura.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_abertura.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("guarani")(total_abertura.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_abertura.real),
        },
      ];
      corpo_abertura_fechamento.table.body.push(body_total_abertura);
    }

    if (total_fechamento) {
      let body_total_fechamento = [
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "center",
          text: total_fechamento.tipo,
          fillColor: "#EBEEF9",
          bold: true,
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_fechamento.dolar),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("guarani")(total_fechamento.guarani),
        },
        {
          fontSize: 10,
          margin: [5, 5, 5, 5],
          alignment: "right",
          text: Vue.filter("currency")(total_fechamento.real),
        },
      ];
      corpo_abertura_fechamento.table.body.push(body_total_fechamento);
    }
  }

    let tabela = [
      cabecalho,
      cabecalho_saldo_caixa,
      corpo_totais,
      corpo_abertura_fechamento ? corpo_abertura_fechamento : "",
    ];
    geral.push(tabela);

    docDefinition = {
      info: {
        title: "FECHAMENTO DE CAIXA",
        author: "PH7",
      },
      pageSize: "A4",
      pageMargins: [15, 15, 15, 15],
      pageOrientation: "landscape", //"portrait"
      defaultStyle: {
        font: "Roboto",
        columngap: 20,
      },
      content: [...geral],
    };

    pdfMake.fonts = {
      Roboto: {
        normal: "Roboto-Regular.ttf",
        bold: "Roboto-Medium.ttf",
      },
    };

    const pdfDocGenerator = pdfMake.createPdf(docDefinition);

    pdfDocGenerator.getDataUrl((data) => {
      result.pdfData = data;
      resolve(result);
    });
  });
}
